import React from "react";
import App from "next/app";
import * as Sentry from "@sentry/node";
import "../scss/style.scss";

Sentry.init({
  // Disable Sentry error fetching in development mode
  enabled:
    process.env.NODE_ENV === "production" ||
    process.env.ENVIRONMENT === "Development",
  dsn: process.env.SENTRY_DSN,
  environment: process.env.ENVIRONMENT,
});

export default class extends App {
  render() {
    const { Component, pageProps } = this.props;

    // Workaround for https://github.com/zeit/next.js/issues/8592
    const { err } = this.props;
    const modifiedPageProps = { ...pageProps, err };

    return <Component {...modifiedPageProps} />;
  }
}
